import weatherIcons from "../img/weather/*.svg";
import {windColor, tempColor, waveColor, rainColor, periodColor, config} from "./config";
import * as d3 from "d3";
import {getAsync} from "./utils";
import navIcons from "../img/nav/*.svg";
import router from "./router";
import {scaleTimelineSlider} from "./timeline";

export const weatherHeader =
  '  <div class="heading_table">' +
  '      <div class="table_header_1 details_btn"><span class="short_label">Néb.</span><span>Nébulosité</span><span><button onclick="wm.toggleLines(this, \'neb_haute\', \'neb_moyenne\', \'neb_basse\');" class="button"></button></span></div>' +
  '      <div class="neb_haute table_header_1" style="display:none;"><span class="short_label">Haute</span><span>Nébulosité haute</span> <span>%</span></div>' +
  '      <div class="table_header_1 neb_moyenne" style="display:none;"><span class="short_label">Moy.</span><span>Nébulosité moyenne</span> <span>%</span></div>' +
  '      <div class="table_header_1 neb_basse" style="display:none;"><span class="short_label">Basse</span><span>Nébulosité basse</span> <span>%</span></div>' +
  '      <div class="table_header_1"><span class="short_label">Temp.</span><span>Température</span> <span>°C</span></div>' +
  '      <div class="table_header_1 details_btn"><span class="short_label">Préc.</span><span>Précipitation</span> <span>mm/h</span> <span><button onclick="wm.toggleLines(this, \'humidity_values\', \'pression_values\');" class="button"></button></span></div>' +
  '      <div class="table_header_1 pression_values " style="display:none;"><span class="short_label">P</span><span>Pression</span> <span>(hPa)</span></div>' +
  '      <div class="table_header_1 humidity_values" style="display:none;"><span class="short_label">Hum.</span><span>Humidité</span></div>' +
  '      <div class="table_header_1 th-border-top"><span class="short_label">Vit.</span><span>Vitesse</span> <span>nds</span></div>' +
  '      <div class="table_header_1"><span class="short_label">Raf.</span><span>Rafales</span> <span>nds</span></div>' +
  '      <div class="table_header_1 zindex_high details_btn"><span class="short_label">Dir.</span><span>Direction</span><span><button onclick="wm.toggleLines(this, \'wind_direction_values\');" class="button"></button></span></div>' +
  '      <div class="table_header_1 wind_direction_values" style="display:none;"><span class="short_label">Dir.</span><span>Direction</span> <span>°</span></div>' +
  '      <div class="table_header_1 th-border-top" ><span class="short_label">Mer tot.</span><span>Mer totale - hauteur</span> <span>m</span></div>' +
  '      <div class="table_header_1 zindex_high" ><span class="short_label">Dir.</span><span>Direction</span></div>' +
  '      <div class="table_header_1 details_btn" ><span class="short_label">Période</span><span>Période (s)</span>  <span><button onclick="wm.toggleLines(this, \'primary_swell_direction\',\'primary_swell_height\',\'primary_swell_period\', \'secondary_swell_values\', \'secondary_swell_height\',\'secondary_swell_period\');" class="button"></button></span></div>' +
  '      <div class="table_header_1 zindex_high primary_swell_direction" style="display:none;"><span class="short_label">HP dir.</span><span>Houle primaire - direction</span></div>' +
  '      <div class="table_header_1 primary_swell_height" style="display:none;"><span class="short_label">H (m)</span><span>Hauteur (m)</span></div>' +
  '      <div class="table_header_1 primary_swell_period" style="display:none;"><span class="short_label">P (s)</span><span>Période (s)</span></div>' +
  '      <div class="table_header_1 zindex_high secondary_swell_values" style="display:none;"><span class="short_label">HS dir.</span><span>Houle secondaire - direction</span></div>' +
  '      <div class="table_header_1 secondary_swell_height" style="display:none;"><span class="short_label">H (m)</span><span>Hauteur (m)</span></div>' +
  '      <div class="table_header_1 secondary_swell_period" style="display:none;"><span class="short_label"> P (s)</span><span>Période (s)</span></div>' +
  '      <div class="table_header_1">Source Météo France</div>' +
  '  </div>';

export const weatherTable =
  '    <div class="content_table">' +
  '      <div class="details_hours"></div>' +
  '      <div class="details_neb"></div>' +
  '      <div class="neb_haute details_nebh" style="display:none;"></div>' +
  '      <div class="neb_moyenne details_nebm" style="display:none;"></div>' +
  '      <div class="neb_basse details_nebl" style="display:none;"></div>' +
  '      <div class="details_temp"></div>' +
  '      <div class="details_rain"></div>' +
  '      <div style="display:none;" class="pression_values details_pres"></div>' +
  '      <div style="display:none;" class="humidity_values details_rh"></div>' +
  '      <div class="details_ws"></div>' +
  '      <div class="details_wg"></div>' +
  '      <div class="details_wd"></div>' +
  '      <div style="display:none;" class="wind_direction_values details_wd_deg"></div>' +
  '      <div class="details_hs"></div>' +
  '      <div class="details_ds"></div>' +
  '      <div class="details_ps"></div>' +
  '      <div class="details_dsp primary_swell_direction" style="display:none;"></div>' +
  '      <div style="display:none;" class="primary_swell_height details_hsp"></div>' +
  '      <div style="display:none;" class="primary_swell_period details_psp"></div>' +
  '      <div style="display:none;" class="secondary_swell_values details_dss"></div>' +
  '      <div style="display:none;" class="secondary_swell_height details_hss"></div>' +
  '      <div style="display:none;" class="secondary_swell_period details_pss"></div>' +
  '      <div class="details_source"></div>' +
  '    </div>';

export function weatherDetails(dt) {
  const now = new Date().getTime() / 1000, prevHour = now - (now % 3600);
  getAsync(`${config.backEndUrl}/live_forecasts/details.json?poi=${dt.id}&timestamp=${prevHour - 24 * 3600}`, function(details) {
    document.querySelector("#timeline").classList.add('weather_details');
    document.querySelector("#weather_details_header").innerHTML = weatherHeader;
    document.querySelector("#weather_title").innerHTML = '<h2>' + dt.name + '</h2>';
    document.querySelector("#weather_details").innerHTML = weatherTable;
    document.querySelector("#weather_buttons").innerHTML =
      '<button id="modal_favorite" data-slug="' + dt.slug + '" data-lat="' + dt.lat + '" data-lng="' + dt.lng + '" type="button" onclick="wm.setFavorite(this);"><i class="fas fa-star"></i><span>Définir comme favori</span></button>' +
      '<button aria-label="Fermer" onclick="wm.closePoi()"><i class="far fa-times"></i></button>';

    let allHours = details.timestamps.reduce(function (currentHours, newHours) { return currentHours.concat(newHours[1]); }, []);

    // d3.select("#weather_details .details_header").selectAll(".table_day").data(details.timestamps).join("div")
    //   .attr("class", "table_day").attr("data-number", function(d) { return  d[1].length }).attr("style", function(d) { return "width: " + (d[1].length * 30) + "px;"; }).text(function(d) { return d[0]; });

    d3.select("#weather_details .details_hours").selectAll(".table_hour").data(allHours).join("div")
      .attr("class", "table_hour").attr("data-hour", function(d) { return d; }).text(function(d) { return d; });

    d3.select("#weather_details .details_neb").selectAll(".table_neb_icon").data(details.data.tcc)
      .join("div").attr("class", "table_neb_icon").text(function(d) {return d;});
    d3.select("#weather_details .details_neb").selectAll(".table_neb_icon").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_nebh").selectAll(".table_neb_color").data(details.data.hcc)
      .join("div").attr("class", "table_neb_color").text(function(d) {return d;});
    d3.select("#weather_details .details_nebh").selectAll(".table_neb_color").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_nebm").selectAll(".table_neb_color").data(details.data.mcc)
      .join("div").attr("class", "table_neb_color").text(function(d) {return d;});
    d3.select("#weather_details .details_nebm").selectAll(".table_neb_color").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_nebl").selectAll(".table_neb_color").data(details.data.lcc)
      .join("div").attr("class", "table_neb_color").text(function(d) {return d;});
    d3.select("#weather_details .details_nebl").selectAll(".table_neb_color").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_temp").selectAll(".table_temp").data(details.data.temp)
      .join("div").attr("class", "table_temp").text(function(d) {return d;});
    d3.select("#weather_details .details_temp").selectAll(".table_temp").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_rain").selectAll(".table_rain_color").data(details.data.rain)
      .join("div").attr("class", "table_rain_color").text(function(d, i) {return (i === 0 || details.data.rain[i-1] === d || d === 0) ? '' : (details.data.rain[i-1] >= d ? d : (Math.round(d * 10 - details.data.rain[i-1] * 10) / 10));});
    d3.select("#weather_details .details_rain").selectAll(".table_rain_color").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_ws").selectAll(".table_wind").data(details.data.wind_s)
      .join("div").attr("class", "table_wind").text(function(d) {return d;});
    d3.select("#weather_details .details_ws").selectAll(".table_wind").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_wg").selectAll(".table_wind").data(details.data.gust_s)
      .join("div").attr("class", "table_wind").text(function(d) {return d;});
    d3.select("#weather_details .details_wg").selectAll(".table_wind").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_wd").selectAll(".table_wind_icon").data(details.data.wind_d)
      .join("div").attr("class", "table_wind_icon").append("img").attr("class", "wind_dir_arrow img-small").attr("src", navIcons["fleche-vent"])
      .attr("style", function(d) { return "transform: rotate(" + (90 - d) + "deg); transform-origin: 50% 50% 0px; display: inline;"; });
    d3.select("#weather_details .details_wd").selectAll(".table_wind_icon").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_wd_deg").selectAll(".table_wind_deg").data(details.data.wind_d)
      .join("div").attr("class", "table_wind_deg").text(function(d) {return (270 - d) % 360;});
    d3.select("#weather_details .details_wd_deg").selectAll(".table_wind_deg").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_hs").selectAll(".table_wave_height").data(details.data.wave_h)
      .join("div").attr("class", "table_wave_height").text(function(d) {return d;});
    d3.select("#weather_details .details_hs").selectAll(".table_wave_height").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_ds").selectAll(".table_wind_icon").data(details.data.wave_d)
      .join("div").attr("class", "table_wind_icon").append("img").attr("class", function(d) { return d ? "img-small" : "hidden"; }).attr("src", navIcons["fleche-vague"])
      .attr("style", function(d) { return "transform: rotate(" + (d - 180) + "deg); transform-origin: 50% 50% 0px; display: inline;"; });
    d3.select("#weather_details .details_ds").selectAll(".table_wind_icon").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_ps").selectAll(".table_wind_period").data(details.data.ptp)
      .join("div").attr("class", "table_wind_period").text(function(d) {return d;});
    d3.select("#weather_details .details_ps").selectAll(".table_wind_period").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_dsp").selectAll(".table_wind_icon").data(details.data.pdir1)
      .join("div").attr("class", "table_wind_icon").append("img").attr("class", function(d) { return d ? "img-small" : "hidden"; }).attr("src", navIcons["fleche-vague"])
      .attr("style", function(d) { return "transform: rotate(" + (d - 180) + "deg); transform-origin: 50% 50% 0px; display: inline;"; });
    d3.select("#weather_details .details_dsp").selectAll(".table_wind_icon").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_hsp").selectAll(".table_wind_period").data(details.data.phs1)
      .join("div").attr("class", "table_wind_period txt_smaller").html(function(d) {return d;});
    d3.select("#weather_details .details_hsp").selectAll(".table_wind_period").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_psp").selectAll(".table_wind_period").data(details.data.ptp1)
      .join("div").attr("class", "table_wind_period").text(function(d) {return d;});
    d3.select("#weather_details .details_psp").selectAll(".table_wind_period").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_dss").selectAll(".table_wind_icon").data(details.data.pdir0)
      .join("div").attr("class", "table_wind_icon").append("img").attr("class", function(d) { return d ? "img-small" : "hidden"; }).attr("src", navIcons["fleche-vague"])
      .attr("style", function(d) { return "transform: rotate(" + (d - 180) + "deg); transform-origin: 50% 50% 0px; display: inline;"; });
    d3.select("#weather_details .details_dss").selectAll(".table_wind_icon").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_hss").selectAll(".table_wind_period").data(details.data.phs0)
      .join("div").attr("class", "table_wind_period txt_smaller").html(function(d) {return d;});
    d3.select("#weather_details .details_hss").selectAll(".table_wind_period").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });


    d3.select("#weather_details .details_pss").selectAll(".table_wind_period").data(details.data.ptp0)
      .join("div").attr("class", "table_wind_period").text(function(d) {return d;});
    d3.select("#weather_details .details_pss").selectAll(".table_wind_period").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });

    d3.select("#weather_details .details_pres").selectAll(".table_wind_pression").data(details.data.pr)
      .join("div").attr("class", "table_wind_pression").text(function(d) {return d ? (d / 100.0) : '';});
    d3.select("#weather_details .details_pres").selectAll(".table_wind_pression").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });

    d3.select("#weather_details .details_rh").selectAll(".table_rh").data(details.data.rh)
      .join("div").attr("class", "table_rh txt_smaller").text(function(d) {return d;});
    d3.select("#weather_details .details_rh").selectAll(".table_rh").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });

    d3.select("#weather_details .details_source").selectAll(".table_source").data(details.source)
      .join("div").attr("class", "table_source").text(function(d) { return d.source ? d.source.substring(0, 3).toUpperCase() : '';});
    d3.select("#weather_details .details_source").selectAll(".table_source").data(allHours)
      .join("div").attr("data-hour", function(d) { return d; });

    setWindColor(); setTempColor(); setNebColor(); setNebIcon(); setRainIcon(); setWaveColor(); setWavePeriodColor();
    setRainColor(); setPressionColor(); setNightColor(); initFavorite(dt.slug);

    scaleTimelineSlider(true, true);
  });
}

function setWindColor() {
  var i, windSpeed, color, windCells = document.getElementsByClassName("table_wind");
  for (i = 0; i < windCells.length; i++) {
    windSpeed = parseFloat(windCells[i].innerText || windCells[i].textContent);
    color = windColor(windSpeed);
    windCells[i].style.color = color.text;
    windCells[i].style.backgroundColor = color.bg;
  }
}

function setTempColor() {
  var i, temp, tempCells = document.getElementsByClassName("table_temp");
  for (i = 0; i < tempCells.length; i++) {
    temp = parseFloat(tempCells[i].innerText || tempCells[i].textContent);
    tempCells[i].style.color = tempColor(temp);
  }
}

function setWaveColor() {
  var i, waveHeight, waveCells = document.getElementsByClassName("table_wave_height");
  for (i = 0; i < waveCells.length; i++) {
    waveHeight = parseFloat(waveCells[i].innerText || waveCells[i].textContent);
    waveCells[i].style.backgroundColor = waveColor(waveHeight);
  }
}

function setNebColor() {
  var i, neb, nebCells = document.getElementsByClassName("table_neb_color");
  for (i = 0; i < nebCells.length; i++) {
    neb = parseFloat(nebCells[i].innerText || nebCells[i].textContent);
    if (neb < 13) {
      nebCells[i].style.backgroundColor = "#ffffff";
    } else if (neb > 14 && neb < 38) {
      nebCells[i].style.backgroundColor = "#D9D9D9";
    } else if (neb > 37 && neb < 63) {
      nebCells[i].style.backgroundColor = "#BEBEBE";
      nebCells[i].style.color= "#FFFFFF";
    } else if (neb > 62 && neb < 88) {
      nebCells[i].style.backgroundColor = "#A6A5A6";
      nebCells[i].style.color= "#FFFFFF";
    } else if (neb > 87 && neb < 101) {
      nebCells[i].style.backgroundColor =  "#808181";
      nebCells[i].style.color= "#FFFFFF";
    } else {
      nebCells[i].style.backgroundColor = "#FFFFFF";
    }
  }
}

function setRainColor() {
  var i, rainHeight, rainCells = document.getElementsByClassName("table_rain_color");
  for (i = 0; i < rainCells.length; i++) {
    rainHeight = parseFloat(rainCells[i].innerText || rainCells[i].textContent);
    rainCells[i].style.backgroundColor = rainColor(rainHeight);
  }
}

function setWavePeriodColor() {
  var i, period, periodCells = document.querySelectorAll(".details_ps .table_wind_period");
  for (i = 0; i < periodCells.length; i++) {
    period = parseFloat(periodCells[i].innerText || periodCells[i].textContent);
    periodCells[i].style.backgroundColor = periodColor(period);
  }
}

function setNebIcon() {
  let i, nebH, nebM, nebL, rain, hour, icon;
  let nebCells = document.querySelectorAll("#weather_details .table_neb_icon"),
    nebHCells = document.querySelectorAll("#weather_details .details_nebh .table_neb_color"),
    nebMCells = document.querySelectorAll("#weather_details .details_nebm .table_neb_color"),
    nebLCells = document.querySelectorAll("#weather_details .details_nebl .table_neb_color"),
    rainCells = document.querySelectorAll("#weather_details .table_rain_color"),
    hourCells = document.querySelectorAll("#weather_details .table_hour");

  for (i = 0; i < nebCells.length; i++) {
    nebH = nebHCells[i] ? parseFloat(nebHCells[i].innerText || nebHCells[i].textContent) : 0;
    nebM = nebMCells[i] ? parseFloat(nebMCells[i].innerText || nebMCells[i].textContent) : 0;
    nebL = nebLCells[i] ? parseFloat(nebLCells[i].innerText || nebLCells[i].textContent) : 0;
    rain = rainCells[i] ? parseFloat(rainCells[i].innerText || rainCells[i].textContent) : 0;
    hour = hourCells[i] ? parseFloat(hourCells[i].innerText || hourCells[i].textContent) : 0;

    icon = computeNebIcon(nebH, nebM, nebL, hour, rain);
    // console.log('computeNebIcon - neb : ' +  nebH+ ' - hour : ' + hour + ' - rain : ' + rain + ' -> icon : ' + icon);
    nebCells[i].innerHTML = icon ? ('<img class="img-icon" src="' + weatherIcons[icon] + '" alt="' + icon + '">') : '';
  }
}

export function computeNebIcon(nebH, nebM, nebL, hour, rain) {
  // console.log('computeNebIcon: ' + JSON.stringify({nebH, nebM, nebL, hour, rain}));
  let icon = '', sunOrMoon = (hour < 21 && hour > 6) ? 'soleil' : 'lune';
  /* nebM et nebL ce qui prévaut c'est le plus couvert*/
  if  (nebM <= nebL)
  {
      /* Ciel dégagé ou voilé */
      if (nebM <= 19 && nebL <= 19) {
        if (nebH <= 80) {
          /* ciel clair */
          icon = applyRain(sunOrMoon, rain);
        } else {
          /* ciel voilé */
          icon = applyRain(sunOrMoon + '-voile', rain);
        }
      }
      /* 1 nuage */
      else if ((nebL >= 20 && nebL <= 49)) {
        icon = applyRain(sunOrMoon + '-nuage-1', rain);
      }
      /* 2 nuages */
      else if ( (nebL >= 50 && nebL <= 79)) {
        icon = applyRain(sunOrMoon + '-nuage-2', rain);
      }
      /* 3 nuages */
      else if (nebL >= 80) {
        icon = applyRain('nuage-3', rain);
      }
  }
  /* nebM et nebL ce qui prévaut c'est le plus couvert*/
  else if (nebM > nebL) {
    /* Ciel dégagé ou voilé */
    if (nebM <= 19 && nebL <= 19) {
      if (nebH <= 80) {
        /* ciel clair */
        icon = applyRain(sunOrMoon, rain);
      } else {
        /* ciel voilé */
        icon = applyRain(sunOrMoon + '-voile', rain);
      }
    }
    /* 1 nuage */
    else if ((nebM >= 20 && nebM <= 49)) {
      icon = applyRain(sunOrMoon + '-nuage-1', rain);
    }
    /* 2 nuages */
    else if ( (nebM >= 50 && nebM <= 79)) {
      icon = applyRain(sunOrMoon + '-nuage-2', rain);
    }
    /* 3 nuages */
    else if (nebM >= 80) {
      icon = applyRain('nuage-3', rain);
    }

    }
  return icon;
}

function applyRain(prefix, rain) {
  let icon;
  if (rain === 0) {
    icon = prefix;
  } else if (rain <= 2.5) {
    icon = prefix + '-pluie-1';
  } else if (rain > 2.5 && rain <= 7.6) {
    icon = prefix + '-pluie-2';
  } else if (rain > 7.6) {
    icon = prefix + '-pluie-3';
  } else {
    icon = prefix;
  }
  return icon;
}

function setRainIcon() {
  var i, x, icon, y = document.getElementsByClassName("table_rain_icon");
  for (i = 0; i < y.length; i++) {
    x = parseFloat(y[i].innerText || y[i].textContent);

    if (x <= 2.5) {
      icon = 'nuage-pluie-1';
    } else if (x > 2.5 && x < 7.7) {
      icon = 'nuage-pluie-2';
    } else if (x >= 7.7) {
      icon = 'nuage-pluie-3';
    } else {
      icon = '';
    }
    y[i].innerHTML = icon ? ('<img class="img-icon" src="img/weather/' + icon + '.svg" alt="' + icon + '">') : '';
  }
}

function setPressionColor() {
  var i, txt, x, y = document.getElementsByClassName("table_wind_pression");
  for (i = 0; i < y.length; i++) {
    txt = y[i].innerText || y[i].textContent;
    if (txt) {
      x = parseFloat(y[i].innerText || y[i].textContent);
      if (x < 1000) {
        y[i].style.fontWeight = "900";
        y[i].innerHTML = Math.trunc(x);
      } else if (x >= 1000 && x < 1020) {
        y[i].style.fontWeight = "500";
        y[i].innerHTML = Math.trunc(x);
      } else if (x >= 1020) {
        y[i].style.fontWeight = "900";
        y[i].innerHTML = Math.trunc(x);
      } else {
        y[i].style.fontWeight = "500";
        y[i].innerHTML = Math.trunc(x);
      }
    }
  }
}

function setNightColor() {
  var i, x, y = document.getElementsByClassName("table_hours");
  for (i = 0; i < y.length; i++) {
    x = parseFloat(y[i].innerText || y[i].textContent);
    if (x > 6 && x < 21) {
      y[i].style.backgroundColor = "rgb(255,255,255)";
    } else {
      y[i].style.backgroundColor = "rgb(229,229,229)";
    }
  }
}

function initFavorite(slug){
  let button = document.querySelector("#modal_favorite");
  let text = button.querySelector('span');
  let navBtn = document.querySelector("#map_favorite button");
  if (config.favoritePath === '/previsions/' + slug) {
    button.classList.add("is_favorite");
    text.innerHTML = "Supprimer le favori";
    if (navBtn.classList.contains("no-favorite")) {
      navBtn.classList.remove("no-favorite");
    }
  } else {
    button.classList.remove("is_favorite");
    text.innerHTML = "Définir comme favori";
  }
}

export function setFavorite(element) {
  var slug = element.getAttribute("data-slug");
  var button = document.querySelector("#modal_favorite");
  var navBtn = document.querySelector("#map_favorite button");
  var text = button.querySelector('span');
    if (config.favoritePath === '/previsions/'+slug) {
      localStorage.removeItem('path');
      config.favoritePath ="";
      button.classList.remove("is_favorite");
      if(!navBtn.classList.contains("no-favorite")){
        navBtn.classList.add("no-favorite");
      }
      text.innerHTML = "Définir comme favori";
    } else {
      button.classList.add("is_favorite");
      text.innerHTML = "Supprimer le favori";
      if(navBtn.classList.contains("no-favorite")){
        navBtn.classList.remove("no-favorite");
      }
      localStorage.setItem('path', '/previsions/'+slug);
      config.favoritePath = '/previsions/'+slug;
   }
}

export function closePoi() {
  router.navigate("/");
  document.querySelector("#timeline").classList.remove('weather_details');
  document.querySelector("#weather_title").innerHTML = '';
  scaleTimelineSlider(true, false);
  removeActivePoi();
}

export function setPoiButton(slug) {
  removeActivePoi();
  let poi = document.querySelector("#poi_" + slug);
  poi.classList.add("poi-active");
}

export function removeActivePoi(){
  let poiActive = document.querySelector(".poi-active");
  if (poiActive) {
    poiActive.classList.remove("poi-active");
  }
}


